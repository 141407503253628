import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Initiate from "./BulkCharges/Initiate"
import List from "./BulkCharges/List"
import FetchBatch from "./BulkCharges/FetchBatch"
import FetchCharge from "./BulkCharges/FetchCharge"
import Pause from "./BulkCharges/Pause"
import Resume from "./BulkCharges/Resume"


import apis from "../data/bulk-charge.json"

const BulkCharges = () => {

  return (
    <SectionLayout id="section-bulk-charge">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Bulk Charges" id="bulk-charge" />
          <p>
            The Bulk Charges API allows you create and manage multiple recurring payments from your customers
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Initiate data={ apis.initiate } />
        <List data={ apis.list } />
        <FetchBatch data={ apis["fetch-batch"] } />
        <FetchCharge data={ apis["fetch-charge"] } />
        <Pause data={ apis.pause } />
        <Resume data={ apis.resume } />
      </>
    </SectionLayout>
  )
}

export default BulkCharges
import React from "react";


const ExternalLink = ({ link, title }) => {
  return(
    <>
      <a href={ link } target="_blank" rel="noopener noreferrer">
        { title }
      </a>
    </>
  )
}

export default ExternalLink
const stat_200 = `{
  "status": true,
  "message": "BVN lookup successful",
  "data": {
    "bvn": "000000000000",
    "is_blacklisted": false,
    "account_number": true,
    "first_name": true,
    "last_name": true
  },
  "meta": {
    "calls_this_month": 1,
    "free_calls_left": 9
  }
}`

export { stat_200 }
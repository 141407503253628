const stat_200_failed = `{
  "status": false,
  "message": "Charge attempted",
  "data": {
      "status": "failed",
      "message": "Insufficient funds"
  }
}`

const stat_200 = `{
  "message": "Charge attempted",
  "status": true,
  "data": {
    "message": "",
    "paidAt": "2020-05-21T15:16:00.000Z",
    "plan": null,
    "log": null,
    "ip_address": "35.177.113.19",
    "createdAt": "2020-05-21T15:14:25.000Z",
    "domain": "live",
    "fees": 390,
    "metadata": "",
    "requested_amount": 10000,
    "id": 102039,
    "currency": "NGN",
    "status": "success",
    "transaction_date": "2020-05-21T15:14:25.000Z",
    "reference": "7c7rpkqpc0tijs8",
    "subaccount": {},
    "customer": {
      "email": "ben@jude.com",
      "last_name": "Ben",
      "metadata": null,
      "customer_code": "CUS_bpy9ciomcstg55y",
      "risk_action": "default",
      "first_name": "Jude",
      "phone": "",
      "id": 16200
    },
    "order_id": null,
    "plan_object": {},
    "authorization": {
      "signature": "SIG_5wBvKoAT64nwSJgZkHvQ",
      "authorization_code": "AUTH_82e26bc6yb",
      "reusable": true,
      "exp_month": "08",
      "card_type": "visa DEBIT",
      "last4": "4633",
      "account_name": null,
      "channel": "card",
      "brand": "visa",
      "country_code": "US",
      "bin": "440066",
      "bank": "",
      "exp_year": "2024",
      "account_name": "BoJack Horseman"
    },
    "channel": "card",
    "amount": 10000,
    "created_at": "2020-05-21T15:14:25.000Z",
    "gateway_response": "Approved",
    "fees_split": null,
    "paid_at": "2020-05-21T15:16:00.000Z"
  }
}`

const stat_400 = `{
  "status": false,
  "message": "Transaction reference is invalid"
}`

export { stat_200_failed, stat_200, stat_400 }
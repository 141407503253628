import React from 'react'

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import ExternalLink from "../components/ExternalLink/ExternalLink"
import Callout from "../components/Callout/Callout"

const Authentication = () => {
  return (
    <SectionLayout id="section-authentication">
      <>
        <Permalink type="h1" title="Authentication" id="authentication" />
        <section>
          <p>
            Authenticate your API calls by including your secret key in the Authorization header of every request you make. You can manage your API keys from the <ExternalLink link="https://dashboard.paystack.co/#/settings/developer" title="dashboard" /> .
          </p>
          <p>
            Generally, we provide both public and secret keys. Public keys are meant to be used from your front-end when integrating using Paystack Inline and in our Mobile SDKs only. By design, public keys cannot modify any part of your account besides initiating transactions to you. The secret keys however, are to be kept secret. If for any reason you believe your secret key has been compromised or you wish to reset them, you can do so from the dashboard.
          </p>
          <Callout type="danger" title="Secure your secret key">
            Do not commit your secret keys to git, or use them in client-side code.
          </Callout>
          <p>
            Authorization headers should be in the following format: <code>Authorization: Bearer SECRET_KEY</code>
          </p>
          <Callout type="info" title="Sample Authorization Header">
            Authorization: Bearer sk_test_shdjkhdj827391nV4Lid
          </Callout>
          <p>
            API requests made without authentication will fail with the status code <code>401: Unauthorized</code>. All API requests must be made over HTTPS. 
          </p>
          <Callout type="danger" title="Secure your requests">
            Do not set <code>VERIFY_PEER</code> to <code>FALSE</code>. Ensure your server verifies the SSL connection to Paystack.
          </Callout>
        </section>
      </>
    </SectionLayout>
  )
}


export default Authentication;
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Subaccounts/Create"
import List from "./Subaccounts/List"
import Fetch from "./Subaccounts/Fetch"
import Update from "./Subaccounts/Update"

import apis from "../data/subaccount.json"

const Subaccount = () => {

  return (
    <SectionLayout id="section-subaccount">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Subaccounts" id="subaccount" />
          <p>
            The Subaccounts API allows you create and manage subaccounts on your integration. Subaccounts can be used to split payment between two accounts (your main account and a sub account)
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
      </>
    </SectionLayout>
  )
}

export default Subaccount
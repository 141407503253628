import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Invoices/Create"
import List from "./Invoices/List"
import Fetch from "./Invoices/Fetch"
import Verify from "./Invoices/Verify"
import SendNotification from "./Invoices/SendNotification"
import InvoiceTotal from "./Invoices/InvoiceTotal"
import Finalize from "./Invoices/Finalize"
import Update from "./Invoices/Update"
import Archive from "./Invoices/Archive"

import apis from "../data/invoice.json"

const Invoices = () => {

  return (
    <SectionLayout id="section-invoice">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Invoices" id="invoice" />
          <p>
            The Invoices API allows you issue out and manage payment requests
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Verify data={ apis.verify } />
        <SendNotification data={ apis["send-notification"] } />
        <InvoiceTotal data={ apis.total } />
        <Finalize data={ apis.finalize } />
        <Update data={ apis.update } />
        <Archive data={ apis.archive } />
      </>
    </SectionLayout>
  )
}

export default Invoices
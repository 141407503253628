import React from 'react'

import Callout from "../components/Callout/Callout"
import Permalink from "../components/Permalink/Permalink"
import ExternalLink from "../components/ExternalLink/ExternalLink"
import Tabs from "../components/APITabs/Tabs"
import ParamsTable from "../components/ParamsTable/ParamsTable"
import SectionLayout from "../components/SectionLayout/SectionLayout"

const Introduction = () => {

  return (
    <SectionLayout id="section-introduction">
      <>
        <header>
          <Permalink type="h1" title="Introduction" id="introduction" />
          <p>
            Learn how to integrate our APIs into your application
          </p>
        </header>
        <section>
          <Permalink type="h2" title="API Basics" id="introduction-api-basics" />
          <p>
          The Paystack API gives you access to pretty much all the features you can use on our dashboard and lets you extend them for use in your application. It strives to be RESTful and is organized around the main resources you would be interacting with - with a few notable exceptions.
          </p>
          <Callout type="info" title="Before you do anything">
          You should <ExternalLink link="https://dashboard.paystack.co/#/signup" title="create a free Paystack account" /> that you can test the API against. We will provide you with test keys that you can use to make API calls
          </Callout>
        </section>
        <section>
          <Permalink type="h2" title="Sample Requests" id="introduction-sample-requests" />
          <p>
            We provide sample API calls next to each method using <ExternalLink link="http://curl.haxx.se/" title="cURL"/>. All you need to do is insert your specific parameters, and you can test the calls from the command line. See this tutorial on <ExternalLink link="https://www.baeldung.com/curl-rest" title="using cURL with APIs"/>.
          </p>
          <p>
            You can also use <ExternalLink link="https://www.getpostman.com/downloads/" title="Postman"/> if you aren't familiar with cURL. Postman is an easy to use API development environment for making HTTP requests.
            You can download the <ExternalLink link="https://documenter.getpostman.com/view/2770716/paystack-api/7187aMn?version=latest" title="Paystack Postman Collection"/> to make testing the API easier.
          </p>
        </section>
        <section>
          <Permalink type="h2" title="Requests and Response" id="introduction-requests-response" />
          <p>
            Both request body data and response data are formatted as JSON. Content type for responses will always be <code>application/json</code>. Generally, all responses will be in the following format: </p>
          <Tabs links={{json: "Response Format"}} filepath="/introduction/response-format.js" />
          <p>
            While we generally recommend that developers use HTTP status codes to determine the result of an API call, we have provided a handy <code>status</code> key to let you know upfront if the request was successful or not.
          </p>
          <p>
            The message key is a string which will contain a summary of the response and its status. For instance when trying to retrieve a list of customers, message might read “Customers retrieved”. In the event of an error, the message key will contain a description of the error as with the authorization header situation above. This is the only key that is universal across requests.
          </p>
          <p>
            The data key is where you want to look at for the result of your request. It can either be an object, or an array depending on the request made. For instance, a request to retrieve a single customer will return a customer object in the data key, while the key would be an array of customers if a list is requested instead.
          </p>
          <p>
            The meta key is used to provide context for the contents of the data key. For instance, if a list of transactions performed by a customer is being retrieved, pagination parameters can be passed along to limit the result set. The meta key will then contain an object with the following attributes:
          </p>
          <Tabs links={{json: "Meta Key Structure"}} filepath="/introduction/meta-format.js" />
          <ParamsTable title="Keys" hasOptional="false" data={[
            { param: "Total", type: "Number", description: "This is the total number of transactions that were performed by the customer.", required: true },
            { param: "Skipped", type: "Number", description: "This is the number of records skipped before the first record in the array returned.", required: true },
            { param: "PerPage", type: "Number", description: "<span>This is the maximum number of records that will be returned per request. This can be modified by passing a  new value as a <code>perPage</code> query parameter. <strong>Default: 50</strong></span>", required: true },
            { param: "Page", type: "Number", description: "<span>This is the current <code>page</code> being returned. This is dependent on what page was requested using the <code>page</code> query parameter. <strong>Default: 1</strong></span>", required: true },
            { param: "PageCount", type: "Number", description: "This is how many pages in total are available for retrieval considering the maximum records per page specified. For context, if there are 51 records and perPage is left at its default value, pageCount will have a value of 2.", required: true }
          ]} />
        </section>
      </>
    </SectionLayout>
  )
}


export default Introduction;
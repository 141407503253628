import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"
import Callout from "../../components/Callout/Callout"
import UnorderedList from "../../components/List/UnorderedList"


const CheckAuthorization = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          <Callout type="info" title="Feature Availability">
            This feature is only available to businesses in Nigeria.
          </Callout>
          <p>All Mastercard and Visa authorizations can be checked with this endpoint to know if they have funds for the payment you seek.</p>
          <p>This endpoint should be used when you do not know the exact amount to charge a card when rendering a service. It should be used to check if a card has enough funds based on a maximum range value. It is well suited for: </p>
          <UnorderedList items={[
            <span>Ride hailing services</span>,
            <span>Logistics services</span>
            ]} 
          />
          <Callout type="warning" title="Warning">
            You shouldn't use this endpoint to check a card for sufficient funds if you are going to charge the user immediately. This is because we hold funds when this endpoint is called which can lead to an <strong>insufficient funds</strong> error.
          </Callout>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
        <section>
          <ParamsTable title="Body Param" hasOptional="true" data={props.data.body} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/transactions/check_authorization/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200_ok: "200 OK", stat_400_invalid: "400 Invalid" }} filepath="/transactions/check_authorization/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default CheckAuthorization
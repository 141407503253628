const stat_200 = `{
  "status": true,
  "message": "Refunds retrieved",
  "data": [
    {
      "integration": 463433,
      "transaction": 1183399071,
      "dispute": null,
      "settlement": null,
      "id": 2733802,
      "domain": "test",
      "currency": "NGN",
      "amount": 106000,
      "status": "processed",
      "refunded_at": null,
      "refunded_by": "tolu@me.com",
      "customer_note": "Refund for transaction 413322_21938653397065",
      "merchant_note": "Refund for transaction 413322_21938653397065 by tolu@me.com",
      "deducted_amount": 106000,
      "fully_deducted": true,
      "createdAt": "2021-10-22T10:50:48.000Z"
    },
    {
      "integration": 463433,
      "transaction": 1166090204,
      "dispute": null,
      "settlement": null,
      "id": 1934707,
      "domain": "test",
      "currency": "NGN",
      "amount": 10000,
      "status": "processed",
      "refunded_at": null,
      "refunded_by": "test@me.com",
      "customer_note": "Refund for transaction 404807_21239177380029",
      "merchant_note": "Refund for transaction 404807_21239177380029 by test@me.com",
      "deducted_amount": 10000,
      "fully_deducted": true,
      "createdAt": "2021-06-23T10:03:44.000Z"
    }
  ],
  "meta": {
    "total": 2,
    "skipped": 0,
    "perPage": 50,
    "page": 1,
    "pageCount": 1
  }
}`

export { stat_200 }
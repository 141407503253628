import React from "react"
import { Link } from "gatsby"

import "./api-header.scss"

import APISearch from '../Search/APISearch';
import Logo from "../../assets/images/svg/paystack.svg"


const Header = (props) => {
  return (
    <header className="header api-header">
      <div className="api-header-logo">
        <Link to="/api">
          <Logo />
          <span>api</span>
        </Link>
      </div>
      <div className="api-header-search">
        <APISearch isSearchMobile={ props.isSearchMobile }
					toggleSearch={ props.toggleSearch } />
      </div>
      <div className="api-header-actions">
        <div className="header-actions--web">

        </div>
        <div>
          
        </div>
        <div>
          <a className="api-link-text" href={ process.env.GATSBY_DOC_URL } target="_blank" rel="noopener noreferrer">Docs</a>
        </div>
        <div>
          <a className="api-link-button" href="https://dashboard.paystack.com/" target="_blank" rel="noopener noreferrer">
						Sign up
					</a>
        </div>
      </div>
    </header>
  )
}

export default Header
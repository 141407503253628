const stat_200 = `{
  "status": true,
  "message": "Products retrieved",
  "data": [
    {
      "integration": 343288,
      "name": "Product Four",
      "description": "Product Four Description",
      "product_code": "PROD_l9p81u9pkjqjunb",
      "price": 500000,
      "currency": "NGN",
      "quantity": 0,
      "quantity_sold": null,
      "type": "good",
      "image_path": "",
      "file_path": "",
      "is_shippable": false,
      "unlimited": true,
      "domain": "test",
      "active": true,
      "features": null,
      "in_stock": true,
      "metadata": null,
      "id": 523,
      "createdAt": "2019-06-29T14:06:01.000Z",
      "updatedAt": "2019-06-29T14:06:01.000Z"
    },
    {
      "integration": 343288,
      "name": "Product Five",
      "description": "Product Five Description",
      "product_code": "PROD_8ne9cxutagmtsyz",
      "price": 500000,
      "currency": "NGN",
      "quantity": 0,
      "quantity_sold": null,
      "type": "good",
      "image_path": "",
      "file_path": "",
      "is_shippable": false,
      "unlimited": false,
      "domain": "test",
      "active": true,
      "features": null,
      "in_stock": false,
      "metadata": null,
      "id": 522,
      "createdAt": "2019-06-29T14:04:50.000Z",
      "updatedAt": "2019-06-29T14:04:50.000Z"
    },
    {
      "integration": 343288,
      "name": "Product Three",
      "description": "Product Three Description",
      "product_code": "PROD_numva2ypta07syb",
      "price": 500000,
      "currency": "NGN",
      "quantity": 0,
      "quantity_sold": null,
      "type": "good",
      "image_path": "",
      "file_path": "",
      "is_shippable": false,
      "unlimited": false,
      "domain": "test",
      "active": true,
      "features": null,
      "in_stock": false,
      "metadata": null,
      "id": 521,
      "createdAt": "2019-06-29T14:01:43.000Z",
      "updatedAt": "2019-06-29T14:01:43.000Z"
    },
    {
      "integration": 343288,
      "name": "Product Two",
      "description": "Product Two Description",
      "product_code": "PROD_byz1ess2lpep67b",
      "price": 500000,
      "currency": "NGN",
      "quantity": 0,
      "quantity_sold": null,
      "type": "good",
      "image_path": "",
      "file_path": "",
      "is_shippable": false,
      "unlimited": true,
      "domain": "test",
      "active": true,
      "features": null,
      "in_stock": false,
      "metadata": null,
      "id": 520,
      "createdAt": "2019-06-29T14:01:18.000Z",
      "updatedAt": "2019-06-29T14:01:18.000Z"
    },
    {
      "integration": 343288,
      "name": "Product One",
      "description": "Product One Description",
      "product_code": "PROD_qcie3smv3zxf4vz",
      "price": 500000,
      "currency": "NGN",
      "quantity": 10,
      "quantity_sold": null,
      "type": "good",
      "image_path": "",
      "file_path": "",
      "is_shippable": false,
      "unlimited": false,
      "domain": "test",
      "active": true,
      "features": null,
      "in_stock": true,
      "metadata": null,
      "id": 519,
      "createdAt": "2019-06-29T13:52:42.000Z",
      "updatedAt": "2019-06-29T13:52:42.000Z"
    }
  ],
  "meta": {
    "total": 5,
    "skipped": 0,
    "perPage": 50,
    "page": 1,
    "pageCount": 1
  }
}` 

const stat_401 = `{
  "status": false,
  "message": "Invalid key"
}`

export { stat_200, stat_401 }
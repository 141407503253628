const stat_200 = `{
  "status": true,
  "message": "Transactions retrieved",
  "data": [
    {
      "id": 480955498,
      "reference": "T807207882369486",
      "amount": 10000000,
      "created_at": "2020-02-07T06:36:51.000Z",
      "paidAt": "2020-02-07T06:39:34.000Z",
      "currency": "NGN",
      "channel": "card",
      "domain": "live",
      "message": null,
      "gateway_response": "Approved",
      "fees": 0
    },
    {
      "id": 480806874,
      "reference": "T590843487090242",
      "amount": 100000,
      "created_at": "2020-02-07T05:22:10.000Z",
      "paidAt": "2020-02-07T05:23:29.000Z",
      "currency": "NGN",
      "channel": "card",
      "domain": "live",
      "message": null,
      "gateway_response": "Approved",
      "fees": 0
    }
  ],
  "meta": {
    "total": 2,
    "total_volume": 10100000,
    "skipped": 0,
    "perPage": 100,
    "page": 1,
    "pageCount": 0
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Balance from "./TransfersControl/Balance"
import BalanceLedger from "./TransfersControl/BalanceLedger"
import ResendOTP from "./TransfersControl/ResendOTP"
import DisableOTP from "./TransfersControl/DisableOTP"
import FinalizeDisableOTP from "./TransfersControl/FinalizeDisableOTP"
import EnableOTP from "./TransfersControl/EnableOTP"


import apis from "../data/transfer-control.json"

const TransfersControl = () => {

  return (
    <SectionLayout id="section-transfer-control">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Transfers Control" id="transfer-control" />
          <p>
            The Transfers Control API allows you manage settings of your transfers
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Balance data={ apis.balance } />
        <BalanceLedger data={ apis["balance-ledger"] } />
        <ResendOTP data={ apis["resend-otp"] } />
        <DisableOTP data={ apis["disable-otp"] } />
        <FinalizeDisableOTP data={ apis["finalize-disable-otp"] } />
        <EnableOTP data={ apis["enable-otp"] } />
      </>
    </SectionLayout>
  )
}

export default TransfersControl
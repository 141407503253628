import React from 'react';

import SEO from '../components/seo';
import Layout from '../components/Layout/APILayout'

import '../assets/theme/global.scss';

export default () => {

	return (
		<>
			<SEO title="Paystack API Reference" />
			<Layout />
		</>
	);
};

const stat_200 = `{
  "status": true,
  "message": "Page created",
  "data": {
    "name": "Buttercup Brunch",
    "description": "Gather your friends for the ritual that is brunch",
    "integration": 100032,
    "domain": "test",
    "slug": "5nApBwZkvY",
    "currency": "NGN",
    "active": true,
    "id": 18,
    "createdAt": "2016-03-30T00:49:57.514Z",
    "updatedAt": "2016-03-30T00:49:57.514Z"
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"
import Callout from "../../components/Callout/Callout"


const Create = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          Create a customer on your integration
          <Callout type="info" title="Customer Validation">
            The <code>first_name</code>, <code>last_name</code> and <code>phone</code> are optional parameters. However, when creating a customer that would be assigned a Dedicated NUBAN and your business catgeory falls under <strong>Betting</strong>, <strong>Financial services</strong>, and <strong>General Service</strong>, then these parameters become compulsory.
          </Callout>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
        <section>
          <ParamsTable title="Body Param" hasOptional="true" data={props.data.body} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/customers/create/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200: "200 OK" }} filepath="/customers/create/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default Create
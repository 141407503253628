import React from "react"

import apis from "../data/transaction.json"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Initialize from "./Transactions/Initialize"
import Verify from "./Transactions/Verify"
import List from "./Transactions/List"
import Fetch from "./Transactions/Fetch"
import ChargeAuthorization from "./Transactions/ChargeAuthorization"
import CheckAuthorization from "./Transactions/CheckAuthorization"
import ViewTimeline from "./Transactions/ViewTimeline"
import Totals from "./Transactions/Totals"
import Export from "./Transactions/Export"
import PartialDebit from "./Transactions/PartialDebit"

const Transaction = () => {

  return (
    <SectionLayout id="section-transaction">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Transactions" id="transaction" />
          <p>
            The Transactions API allows you create and manage payments on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Initialize data={ apis.initialize } />
        <Verify data={ apis.verify } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <ChargeAuthorization data={ apis["charge-authorization"] } />
        <CheckAuthorization data={ apis["check-authorization"] } />
        <ViewTimeline data={ apis["view-timeline"] } />
        <Totals data={ apis.totals } />
        <Export data={ apis.export } />
        <PartialDebit data={ apis["partial-debit"] } />
      </>
    </SectionLayout>
  )
}

export default Transaction
const stat_200 = `{
  "status": true,
  "message": "Customer Identification in progress"
}`

const stat_400 = `{
  "status": false,
  "message": "Invalid BVN"
}`

export { stat_200, stat_400}
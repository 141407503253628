import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Callout from "../components/Callout/Callout"

import Create from "./DedicatedNuban/Create"
import List from "./DedicatedNuban/List"
import Fetch from "./DedicatedNuban/Fetch"
import Deactivate from "./DedicatedNuban/Deactivate"
import AddSplit from "./DedicatedNuban/AddSplit"
import RemoveSplit from "./DedicatedNuban/RemoveSplit"
import Providers from "./DedicatedNuban/Providers"
import apis from "../data/dedicated-nuban.json"

const DedicatedNuban = () => {

  return (
    <SectionLayout id="section-dedicated-nuban">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Dedicated NUBAN" id="dedicated-nuban" />
          <p>
            The Dedicated NUBAN API enables Nigerian merchants manage unique payment accounts of their customers.
          </p>
        </header>
        <Callout type="info" title="Feature Availability">
          This feature is only available to businesses in Nigeria.
        </Callout>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Deactivate data={ apis.deactivate } />
        <AddSplit data={ apis["add-split"] } />
        <RemoveSplit data={ apis["remove-split"] } />
        <Providers data={apis.providers} />
      </>
    </SectionLayout>
  )
}

export default DedicatedNuban
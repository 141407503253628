const stat_200 = `{
  "status": true,
  "message": "Balances retrieved",
  "data": [
    {
      "currency": "NGN",
      "balance": 1700000
    }
  ]
}`

export { stat_200 }
const stat_200 = `{
  "status": true,
  "message": "2 transfers queued.",
  "data": [
    {
      "recipient": "RCP_db342dvqvz9qcrn",
      "amount": 50000,
      "transfer_code": "TRF_jblmryckdrc6zq4",
      "currency": "NGN"
    },
    {
      "recipient": "RCP_db342dvqvz9qcrn",
      "amount": 50000,
      "transfer_code": "TRF_yqs7t2w2xndexy7",
      "currency": "NGN"
    }
  ]
}`

const stat_404 = `{
  "status": false,
  "message": "The customer specified has no saved authorizations"
}`

export { stat_200, stat_404 }
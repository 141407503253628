import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Bank from "./Miscellaneous/Bank"
import Providers from "./Miscellaneous/Providers"
import Country from "./Miscellaneous/Country"
import AVSStates from "./Miscellaneous/AVS-States"

import apis from "../data/miscellaneous.json"

const Miscellaneous = () => {

  return (
    <SectionLayout id="section-miscellaneous">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Miscellaneous" id="miscellaneous" />
          <p>
            The Miscellaneous API are supporting APIs that can be used to provide more details to other APIs
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Bank data={ apis.bank } />
        <Providers data={ apis.providers } />
        <Country data={ apis.country } />
        <AVSStates data={apis["avs-states"]} />
      </>
    </SectionLayout>
  )
}

export default Miscellaneous
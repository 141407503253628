const stat_200_ok = `{
  "status": true,
  "message": "Transaction retrieved",
  "data": {
    "id": 292584114,
    "domain": "test",
    "status": "success",
    "reference": "203520101",
    "amount": 10000,
    "message": null,
    "gateway_response": "Successful",
    "paid_at": "2019-10-09T13:03:28.000Z",
    "created_at": "2019-10-09T13:00:16.000Z",
    "channel": "card",
    "currency": "NGN",
    "ip_address": "197.211.43.98",
    "metadata": {
      "custom_fields": [
        {
          "display_name": "Mobile Number",
          "variable_name": "mobile_number",
          "value": "+2348012345678"
        }
      ],
      "referrer": "http://localhost:3001/integration/microphone.html?"
    },
    "log": {
      "start_time": 1570626018,
      "time_spent": 192,
      "attempts": 1,
      "errors": 0,
      "success": true,
      "mobile": false,
      "input": [],
      "history": [
        {
          "type": "action",
          "message": "Attempted to pay with card",
          "time": 191
        },
        {
          "type": "success",
          "message": "Successfully paid with card",
          "time": 192
        }
      ]
    },
    "fees": 150,
    "fees_split": null,
    "authorization": {
      "authorization_code": "AUTH_2e4k18sj52",
      "bin": "408408",
      "last4": "4081",
      "exp_month": "12",
      "exp_year": "2020",
      "channel": "card",
      "card_type": "visa DEBIT",
      "bank": "Test Bank",
      "country_code": "NG",
      "brand": "visa",
      "reusable": true,
      "signature": "SIG_JrPFkMYhcu8AD75eQWKl",
      "account_name": "BoJack Horseman"
    },
    "customer": {
      "id": 1809887,
      "first_name": null,
      "last_name": null,
      "email": "customer@email.com",
      "customer_code": "CUS_0c35ys9w8ma5tbr",
      "phone": null,
      "metadata": null,
      "risk_action": "deny"
    },
    "plan": {},
    "subaccount": {},
    "order_id": null,
    "paidAt": "2019-10-09T13:03:28.000Z",
    "createdAt": "2019-10-09T13:00:16.000Z",
    "requested_amount": 1500000
  }
}`

const stat_200_failed = `{  
  "status":true,
  "message":"Verification successful",
  "data": {  
    "amount":27000,
    "currency":"NGN",
    "transaction_date":"2016-10-01T11:03:09.000Z",
    "status":"failed",
    "reference":"djfoidjkdkj41",
    "domain":"test",
    "metadata":0,
    "gateway_response":"Insufficient Funds",
    "message":null,
    "channel":"card",
    "ip_address":"41.1.25.1",
    "log": {  
      "time_spent":9,
      "attempts":1,
      "authentication":null,
      "errors":0,
      "success":true,
      "mobile":false,
      "input":[ ],
      "channel":null,
      "history": [  
         {  
           "type":"input",
           "message":"Filled these fields: card number, card expiry, card cvv",
           "time":7
         },
         {  
           "type":"action",
           "message":"Attempted to pay",
           "time":7
         },
         {  
           "type":"close",
           "message":"Page closed",
           "time":9
         }
      ]
    },
    "fees":null,
    "authorization":{  
      "authorization_code":"AUTH_8dfhjjdt",
      "card_type":"visa",
      "last4":"1381",
      "exp_month":"08",
      "exp_year":"2018",
      "bin":"412345",
      "bank":"TEST BANK",
      "channel":"card",
      "signature": "SIG_idyuhgd87dUYSHO92D",
      "reusable":true,
      "country_code":"NG",
      "account_name": "BoJack Horseman"
    },
    "customer":{  
      "id":84312,
      "customer_code":"CUS_hdhye17yj8qd2tx",
      "first_name":"BoJack",
      "last_name":"Horseman",
      "email":"bojack@horseman.com"
    },
    "plan":""
  }
}
`

const stat_400_invalid = `{  
  "status":false,
  "message":"Invalid key"
}`

export { stat_200_ok, stat_200_failed, stat_400_invalid }
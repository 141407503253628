import React from 'react'

const SectionLayout = (props) => {
  const id = props.id ? { id: props.id } : {}

  const getTemplate = () => {
    let template = ""
    if(props.children.length === 3) {
      template = <section {...id}>
        <div className="api-section">
          <div className="api-section--copy">
            <div>
              { props.children[0] }
            </div>
          </div>
          { Object.keys(props.children[1].props).length !== 0 && props.children[1].props.constructor === Object && (
            <div className="api-section--code">
              { props.children[1] }
            </div>
          ) }
        </div>
        <>
          { props.children[2] }
        </>
      </section>
    } else if(props.children.length === 2) {
      template = <section {...id}>
          <div className="api-section">
            <div className="api-section--copy">
              <div>
                { props.children[0] }
              </div>
            </div>
            <div className="api-section--code">
              { props.children[1] }
            </div>
          </div>
        </section>
    } else {
      template = <section {...id}>
          <div className="api-section">
            <div className="api-section--copy">
              <div>
                { props.children }
              </div>
            </div>
          </div>
        </section>
    }
    return template;
  }
  
  return (
    <>
      { getTemplate() }
    </>
  )
}

export default SectionLayout
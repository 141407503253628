const stat_200_ok = `{
  "status": true,
  "message": "Verification successful",
  "data": {
    "amount": 27000,
    "currency": "NGN",
    "transaction_date": "2016-10-01T11:03:09.000Z",
    "status": "success",
    "reference": "DG4uishudoq90LD",
    "domain": "test",
    "metadata": 0,
    "gateway_response": "Successful",
    "message": null,
    "channel": "card",
    "ip_address": "41.1.25.1",
    "log": {
      "time_spent": 9,
      "attempts": 1,
      "authentication": null,
      "errors": 0,
      "success": true,
      "mobile": false,
      "input": [],
      "channel": null,
      "history": [{
        "type": "input",
        "message": "Filled these fields: card number, card expiry, card cvv",
        "time": 7
        },
        {
          "type": "action",
          "message": "Attempted to pay",
          "time": 7
        },
        {
          "type": "success",
          "message": "Successfully paid",
          "time": 8
        },
        {
          "type": "close",
          "message": "Page closed",
          "time": 9
        }
      ]
    }
    "fees": null,
    "authorization": {
      "authorization_code": "AUTH_8dfhjjdt",
      "card_type": "visa",
      "last4": "1381",
      "exp_month": "08",
      "exp_year": "2018",
      "bin": "412345",
      "bank": "TEST BANK",
      "channel": "card",
      "signature": "SIG_idyuhgd87dUYSHO92D",
      "reusable": true,
      "country_code": "NG",
      "account_name": "BoJack Horseman"
    },
    "customer": {
      "id": 84312,
      "customer_code": "CUS_hdhye17yj8qd2tx",
      "first_name": "BoJack",
      "last_name": "Horseman",
      "email": "bojack@horseman.com"
    },
    "plan": "PLN_0as2m9n02cl0kp6",
    "requested_amount": 1500000
  }
}`

const stat_200_failed = `{  
  "status":true,
  "message":"Verification successful",
  "data": {  
    "amount":27000,
    "currency":"NGN",
    "transaction_date":"2016-10-01T11:03:09.000Z",
    "status":"failed",
    "reference":"djfoidjkdkj41",
    "domain":"test",
    "metadata":0,
    "gateway_response":"Insufficient Funds",
    "message":null,
    "channel":"card",
    "ip_address":"41.1.25.1",
    "log": {  
      "time_spent":9,
      "attempts":1,
      "authentication":null,
      "errors":0,
      "success":true,
      "mobile":false,
      "input":[ ],
      "channel":null,
      "history": [  
         {  
           "type":"input",
           "message":"Filled these fields: card number, card expiry, card cvv",
           "time":7
         },
         {  
           "type":"action",
           "message":"Attempted to pay",
           "time":7
         },
         {  
           "type":"close",
           "message":"Page closed",
           "time":9
         }
      ]
    },
    "fees":null,
    "authorization":{  
      "authorization_code":"AUTH_8dfhjjdt",
      "card_type":"visa",
      "last4":"1381",
      "exp_month":"08",
      "exp_year":"2018",
      "bin":"412345",
      "bank":"TEST BANK",
      "channel":"card",
      "signature": "SIG_idyuhgd87dUYSHO92D",
      "reusable":true,
      "country_code":"NG",
      "account_name": "BoJack Horseman"
    },
    "customer":{  
      "id":84312,
      "customer_code":"CUS_hdhye17yj8qd2tx",
      "first_name":"BoJack",
      "last_name":"Horseman",
      "email":"bojack@horseman.com"
    },
    "plan":""
  }
}
`

const stat_400_invalid = `{  
  "status":false,
  "message":"Invalid key"
}`

export { stat_200_ok, stat_200_failed, stat_400_invalid }
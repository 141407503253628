const stat_200 = `{
  "status": true,
  "message": "Authorization has been deactivated"
}`

const stat_404 = `{
  "status": false,
  "message": "Authorization code not found."
}`

export { stat_200, stat_404}
const stat_200 = `{
  "status": true,
  "message": "Page updated",
  "data": {
    "domain": "test",
    "name": "Buttercup Brunch",
    "description": "Gather your friends for the ritual that is brunch",
    "amount": null,
    "currency": "NGN",
    "slug": "5nApBwZkvY",
    "active": true,
    "id": 18,
    "integration": 100032,
    "createdAt": "2016-03-30T00:49:57.000Z",
    "updatedAt": "2016-03-30T04:44:35.000Z"
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Customers/Create"
import List from "./Customers/List"
import Fetch from "./Customers/Fetch"
import Update from "./Customers/Update"
import WhitelistBlacklist from "./Customers/WhitelistBlacklist"
import DeactivateAuthorization from "./Customers/DeactivateAuthorization"
import IDValidation from './Customers/IDValidation'
import apis from "../data/customer.json"

const Customer = () => {

  return (
    <SectionLayout id="section-customer">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Customers" id="customer" />
          <p>
            The Customers API allows you create and manage customers on your integration.
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
        <IDValidation data={ apis.validate } />
        <WhitelistBlacklist data={ apis["whitelist-blacklist"] } />
        <DeactivateAuthorization data={ apis["deactivate-authorization"] } />
       
      </>
    </SectionLayout>
  )
}

export default Customer
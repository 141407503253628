const stat_200 = `{
  "status": true,
  "message": "Subscriptions retrieved",
  "data": [
    {
      "customer": {
        "first_name": "BoJack",
        "last_name": "Horseman",
        "email": "bojack@horseman.com",
        "phone": "",
        "metadata": null,
        "domain": "test",
        "customer_code": "CUS_hdhye17yj8qd2tx",
        "risk_action": "default",
        "id": 84312,
        "integration": 100073,
        "createdAt": "2016-10-01T10:59:52.000Z",
        "updatedAt": "2016-10-01T10:59:52.000Z"
      },
      "plan": {
        "domain": "test",
        "name": "Weekly small chops",
        "plan_code": "PLN_0as2m9n02cl0kp6",
        "description": "Small chops delivered every week",
        "amount": 27000,
        "interval": "weekly",
        "send_invoices": true,
        "send_sms": true,
        "hosted_page": false,
        "hosted_page_url": null,
        "hosted_page_summary": null,
        "currency": "NGN",
        "migrate": null,
        "id": 1716,
        "integration": 100073,
        "createdAt": "2016-10-01T10:59:11.000Z",
        "updatedAt": "2016-10-01T10:59:11.000Z"
      },
      "integration": 123456,
      "authorization": {
        "authorization_code": "AUTH_6tmt288t0o",
        "bin": "408408",
        "last4": "4081",
        "exp_month": "12",
        "exp_year": "2020",
        "channel": "card",
        "card_type": "visa visa",
        "bank": "TEST BANK",
        "country_code": "NG",
        "brand": "visa",
        "reusable": true,
        "signature": "SIG_uSYN4fv1adlAuoij8QXh",
        "account_name": "BoJack Horseman"
      },
      "domain": "test",
      "start": 1475319599,
      "status": "active",
      "quantity": 1,
      "amount": 27000,
      "subscription_code": "SUB_6phdx225bavuwtb",
      "email_token": "ore84lyuwcv2esu",
      "easy_cron_id": "275226",
      "cron_expression": "0 0 * * 6",
      "next_payment_date": "2016-10-15T00:00:00.000Z",
      "open_invoice": "INV_qc875pkxpxuyodf",
      "id": 4192,
      "createdAt": "2016-10-01T10:59:59.000Z",
      "updatedAt": "2016-10-12T07:45:14.000Z"
    }
  ],
  "meta": {
    "total": 1,
    "skipped": 0,
    "perPage": 50,
    "page": 1,
    "pageCount": 1
  }
}`

export { stat_200 }
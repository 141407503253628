import React from 'react'

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import OrderedList from '../components/List/OrderedList'
import ParamsTable from "../components/ParamsTable/ParamsTable"

const Error = () => {

  return (
    <SectionLayout id="section-errors" code="true">
      <>
        <Permalink type="h1" title="Errors" id="errors" />
        <section>
          <p>
            As stated earlier, Paystack's API is RESTful and as such, uses conventional HTTP response codes to indicate the success or failure of requests.
          </p>
          <p>We discuss some common errors and their causes below</p>
        </section>
        <section>
          <h3>Authorization code does not exist or has been deactivated</h3>
          <OrderedList items={[
            <span>All authorizations are inactive until one transaction succeeds on the card. Attempting charge auth on an inactive authorization will give this message.</span>,
            <span>
              You can only charge an authorization for the customer who saved it. If an authorization is paired with the wrong email, you may get this error message.
            </span>
            ]} 
          />
        </section>
        <section>
          <h3>This authorization is not reusable</h3>
          <p>Only reusable authorizations can be used with our charge authorization endpoint. Please confirm reusability on the authorization object before making an attempt to avoid this message.</p>
        </section>
        <section>
          <h3>Invalid Subaccount</h3>
          <p>All subaccounts codes on Paystack start with <code>ACCT_</code> and only exist on the integration that created them. And in the domain they were created. Common situations when you will get this message include:</p>
          <OrderedList items={[
            <span>Sending subaccount id or name or any value other than a valid code. Rather don't send any subaccount if you do not intend to split the transaction.</span>,
            <span>
              Sending subaccount code from another integration.
            </span>,
            <span>Sending subaccount code created in the test domain while specifying a live key and vice-versa.</span>
            ]} 
          />
        </section>
        <section>
          <h3>Email does not match Authorization code. Please confirm</h3>
          <p>You can only charge an authorization for the customer who saved it. If an authorization is paired with the wrong email, you may get this error message.</p>
        </section>
        <section>
          <h3>Invalid Split transaction values</h3>
          <p>This happens when you have attempted to split the payment in a way that is mathematically impossible. The transaction may have specified the subaccount as bearer though the subaccount's share doesn't cover paystack's fees or vice-versa.</p> 
          <p>You may also have specified a transaction charge that was above the amount being paid.</p>
        </section>
        <section>
          <h3>Duplicate Transaction Reference</h3>
          <p>Every transaction on your integration in a domain must have a unique refrence. You will get this message if you attempt to start a new transaction with a reference that has already been used.</p>
        </section>
        <section>
          <h3>Invalid ****</h3>
          <p>All objects on Paystack are tied to a domain and integration. Getting an <code>Invalid X</code> message might mean the one specified does not exist in the space commanded by the key presented.</p>
        </section>
      </>
      <>
        <ParamsTable title="Error Codes" hasOptional="false" data={[
            { param: <span>200,&nbsp;201</span>, type: "", description: "Request was successful and intended action was carried out. Note that we will always   send a 200 if a charge or verify request was made. Do check the data object to know how the charge went (i.e. successful or failed).", required:  true },
            { param: "400", type: "", description: "A validation or client side error occurred and the request was not fulfilled.", required: true },
            { param: "401", type: "", description: "The request was not authorized. This can be triggered by passing an invalid secret key in the authorization   header or the lack of one", required: true },
            { param: "404", type: "", description: "Request could not be fulfilled as the request resource does not exist.", required: true },
            { param: <span>500,&nbsp;501,&nbsp;502, 503, 504</span>, type: "", description: "Request could not be fulfilled due to an error on Paystack's end.  This shouldn't happen so please report as soon as you encounter any instance of this.", required: true }
          ]} 
        />
      </>
    </SectionLayout>
  )
}


export default Error;
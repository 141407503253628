import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Plans/Create"
import List from "./Plans/List"
import Fetch from "./Plans/Fetch"
import Update from "./Plans/Update"

import apis from "../data/plan.json"

const Plan = () => {

  return (
    <SectionLayout id="section-plan">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Plans" id="plan" />
          <p>
            The Plans API allows you create and manage installment payment options on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
      </>
    </SectionLayout>
  )
}

export default Plan
const stat_200_otp = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "0t4gwo2ft6q0n9h",
    "status": "send_otp",
    "display_text": "OTP must have at least one character"
  }
}`

const stat_200_pending = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "5bwib5v6anhe9xa",
    "status": "pending"
  }
}`

const stat_200_pin = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "apg8mnjf5yg7zp8",
    "status": "send_pin",
    "display_text": "The PIN must be exactly 4 digits"
  }
}`

const stat_200_failed = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "bc9w93gh629a4d7",
    "status": "failed",
    "message": "Transaction declined. Please use the test card."
  }
}`

const stat_200 = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "amount": 200,
    "currency": "NGN",
    "transaction_date": "2017-05-24T05:56:12.000Z",
    "status": "success",
    "reference": "zuvbpizfcf2fs7y",
    "domain": "test",
    "metadata": {
   	"custom_fields":[
   		{
   			"display_name":"Merchant name",
   			"variable_name":"merchant_name",
   			"value":"Van Damme"
		},
		{
   			"display_name":"Paid Via",
   			"variable_name":"paid_via",
   			"value":"API call"
		}
   	]
   },
    "gateway_response": "Successful",
    "message": null,
    "channel": "card",
    "ip_address": "54.154.89.28, 162.158.38.82, 172.31.38.35",
    "log": null,
    "fees": 3,
    "authorization": {
      "authorization_code": "AUTH_6tmt288t0o",
      "bin": "408408",
      "last4": "4081",
      "exp_month": "12",
      "exp_year": "2020",
      "channel": "card",
      "card_type": "visa visa",
      "bank": "TEST BANK",
      "country_code": "NG",
      "brand": "visa",
      "reusable": true,
      "signature": "SIG_uSYN4fv1adlAuoij8QXh",
      "account_name": "BoJack Horseman"
    },
    "customer": {
      "id": 14571,
      "first_name": null,
      "last_name": null,
      "email": "test@email.co",
      "customer_code": "CUS_hns72vhhtos0f0k",
      "phone": null,
      "metadata": null,
      "risk_action": "default"
    },
    "plan": null
  }
}`

const stat_200_bank_auth = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "apg8mnjf5yg7zp8",
    "url":"https://standard.paystack.co/close",
    "status": "open_url"
  }
}`

const stat_200_phone = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "0t4gwo2ft6q0n9h",
    "status": "send_phone",
    "display_text": "Please send phone"
  }
}`

const stat_200_birthday = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "reference": "0t4gwo2ft6q0n9h",
    "status": "send_birthday",
    "display_text": "Please send your birthday"
  }
}`

const stat_400 = `{
  "status": false,
  "message": "Transaction reference is invalid"
}`

export { stat_200_otp, stat_200_pending, stat_200_pin, stat_200_failed, stat_200, stat_200_bank_auth,
   stat_200_phone, stat_200_birthday, stat_400 }
import React, { useState } from 'react'
import styled from "styled-components"

import Header from './Header'
import CodeSnippet from './CodeSnippet'

const Container = styled.div`
  margin: 0 0 30px;
  background-color: rgba(1,27,51,0.9);
  opacity: 0.9;
  border: 1px solid #0000000f;
  border-radius: 8px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);

  &:last-child {
    margin-bottom: 0;
  }
`

const APIReqRes = ({ type, links, filepath, title }) => {
  let contents = require(`../../api-snippets` + filepath);

  const requestLanguage = Object.keys(links)[0]
  const [requestCodeString, setRequestCodeString] = useState(contents[requestLanguage]);

  const changeLanguage = (language) => {
    setRequestCodeString(contents[language])
  }

  return (
    <Container>
      { type === "response" ? (
        <>
          <Header links={links} changeLanguage={changeLanguage} title="Sample Response" type={ type } />
          <div className="request">
            <CodeSnippet language="json" codeString={requestCodeString} theme={"dark"}/>
          </div>
        </>
      ) : (
        <>
          <Header links={links} changeLanguage={changeLanguage} title={ title } type={ type } />
          <div className="request">
            <CodeSnippet language={ requestLanguage } codeString={requestCodeString} theme={"dark"}/>
          </div>
        </>
      ) }
      
    </Container>
  )
}


export default APIReqRes
import React, { useState, useRef, useEffect } from 'react'
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 8px;
  color: rgba(246, 252, 255, 0.6);
`
const Description = styled.div`
  .title {
    font-size: 13px;
    font-weight: 500;
  }
`

const Label = styled.span`
  background-color: ${props => props.theme.bg};
  color: ${props => props.theme.fg};
  font-size: 10px;
  font-weight: 500;
  padding: 4px 8px;
  margin-right: 6px;
  border-radius: 100px;
  text-transform: uppercase;
`

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 4px;
  width: auto;
  max-width: 100%;

  &:after {
    content: " ";
    position: absolute;
    right: 4px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #aaa;
  }

  select {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--regular-font);
    color: rgba(246, 252, 255, 0.6);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: none;
    background-color: transparent;

    option {
      color: rgba(1, 27, 51, 0.9)
    }

    &:active, &:focus {
      outline: none;
      box-shadow: none;
      border: none;
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 rgba(246, 252, 255, 0.6);
    }
  }

  #compute_select {
    position: absolute;
    top: 40px;
    font-size: 12px;
    font-weight: 500;
  }
`

const Header = ({ links, changeLanguage, title, type }) => {

  const colors = {
    post: {bg: '#E4F6E7', fg: '#177532'},
    put: {bg: '#ffedd6', fg: '#c17d25'},
    get: {bg: '#c7ebff', fg: '#011b33'},
    del: {bg: '#fdd6d6', fg: '#a03939'},
  }

  const [value, setValue] = useState(links[Object.keys(links)[0]])
  const [width, setWidth] = useState('auto')
  const selectRef = useRef(null);

  const getKey = (obj,val) => Object.keys(obj).find(key => obj[key] === val)

  const toggleDropdown = (event) => {
    let value = event.target.value
    updateWidth()
    setValue(value)
    changeLanguage(getKey(links, value))
  }

  const updateWidth = () => {
    const width = selectRef.current.clientWidth + 20
    setWidth(width)
  }

  const baseUrl = ""

  useEffect(() => {
    updateWidth()
  })

  return (
    <Container>
      <Description>
        { type === "response" ? (
          <span className="title">{ title }</span>
        ) : (
          <>
            {/* <span className="label">{ title.method }</span> */}
            <Label theme={colors[title.method.toLowerCase()]}>
              {title.method}
            </Label>
            <span className="title">{ baseUrl }{ title.url }</span>
          </>
        )
        }
      </Description>
      <Dropdown>
        <select onChange={toggleDropdown} value={value} style={{ width: width || "auto" }}>
          {Object.keys(links).map(link => {
              if(link !== "json")
                return <option key={link}>{links[link]}</option>
            }
          )}
        </select>
        <div id="compute_select" ref={ selectRef }>{ value }</div>
      </Dropdown>
    </Container>
  )
}


export default Header
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Callout from "../components/Callout/Callout"

import Initiate from "./Transfers/Initiate"
import Finalize from "./Transfers/Finalize"
import BulkTransfer from "./Transfers/BulkTransfer"
import List from "./Transfers/List"
import Fetch from "./Transfers/Fetch"
import Verify from "./Transfers/Verify"


import apis from "../data/transfer.json"

const Transfers = () => {

  return (
    <SectionLayout id="section-transfer">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Transfers" id="transfer" />
          <p>
            The Transfers API allows you automate sending money on your integration
          </p>
        </header>
        <Callout type="info" title="Feature Availability">
          This feature is only available to businesses in Nigeria and Ghana.
        </Callout>
      </>
      <>
        
      </>
      <>
        <Initiate data={ apis.initiate } />
        <Finalize data={ apis.finalize } />
        <BulkTransfer data={ apis.bulk } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Verify data={ apis.verify } />
      </>
    </SectionLayout>
  )
}

export default Transfers
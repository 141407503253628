import React, { useState, useEffect } from 'react'

import './api-layout.scss'

import APIHeader from '../Header/APIHeader'
import APINav from '../Nav/APINav'
import APIMain from '../Main/APIMain'

import Hamburger from '../../assets/images/svg/hamburger.svg';
import Magnifier from '../../assets/images/svg/search.svg';


const APILayout = () => {

	const [overlay, setOverlay] = useState(false)
	const [mobileSearch, setMobileSearch] = useState(false)
	const [mobileMenu, setMobileMenu] = useState(false)
	const [mobileActions, setMobileActions] = useState(false)
	

	useEffect(() => {
		const body = window.document.body
		if(overlay || mobileSearch) {
			body.classList.add("disable-scroll")
		} else {
			body.classList.remove("disable-scroll")
		}
  }, [overlay, mobileSearch]);

	const toggleSearch = () => {
		setMobileSearch(!mobileSearch)
		setMobileActions(!mobileActions)
	};

	const toggleNav = () => {
		setMobileMenu(!mobileMenu)
		setOverlay(!overlay)
		setMobileActions(!mobileActions)
	};

	return (
		<section className="api-layout">
			<div className={`layout__overlay ${overlay ? 'layout__overlay--mobile' : ''}`}></div>
      <header className="api-layout-header">
				<APIHeader isSearchMobile={ mobileSearch } toggleSearch={ toggleSearch } />
				<div className={`mobile__actions ${mobileActions ? 'mobile__actions--toggle' : ''}`}>
					<button	className="action__button button--search" onClick={toggleSearch}>
						<Magnifier className="action__button__icon" />
						<span className="button__text button__text--right">
							Search
						</span>
					</button>
					<button className="action__button action__button--right-space" onClick={toggleNav}>
						<Hamburger className="action__button__icon" />
						<span className="button__text button__text--right">
							Menu
						</span>
					</button>
				</div>
			</header>
			<nav className={`api-layout-nav  ${ mobileMenu ? 'api-layout-nav--mobile' : ''}`}>
				<APINav toggleNav={ toggleNav } />
			</nav>
			<main className="api-layout-content">
				<APIMain />
			</main>
    </section>
	);
}

export default APILayout

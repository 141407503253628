import React from 'react'
import styled from "styled-components"
import Highlight, { defaultProps } from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/nightOwl'

const Container = styled.div`
  padding: 0px;
`

const Pre = styled.pre`
  position: relative;
  text-align: left;
  padding: 24px 16px;
  margin: 0px;
  overflow-x: auto;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: #011B33;
  max-height: 300px;

  & .token-line {
    line-height: 1.5;
  }
`

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`


const TabContent = ({language, codeString}) => {

  return (
    <Container>
      <Highlight {...defaultProps} theme={theme} code={codeString} language={language}>
        {({ tokens, getLineProps, getTokenProps }) => (
          <Pre>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    </Container>
  )
}


export default TabContent
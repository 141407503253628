import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import FetchTimeout from "./ControlPanel/FetchTimeout"
import UpdateTimeout from "./ControlPanel/UpdateTimeout"

import apis from "../data/control-panel.json"

const ControlPanel = () => {

  return (
    <SectionLayout id="section-control-panel">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Control Panel" id="control-panel" />
          <p>
            The Control Panel API allows you manage some settings on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <FetchTimeout data={ apis["fetch-timeout"] } />
        <UpdateTimeout data={ apis["update-timeout"] } />
      </>
    </SectionLayout>
  )
}

export default ControlPanel
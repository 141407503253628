const stat_200 = `{
  "status": true,
  "message": "Dispute updated successfully",
  "data": [
    {
      "currency": "NGN",
      "last4": null,
      "bin": null,
      "transaction_reference": null,
      "merchant_transaction_reference": null,
      "refund_amount": 1002,
      "status": "resolved",
      "domain": "test",
      "resolution": "merchant-accepted",
      "source": "bank",
      "category": "general",
      "note": null,
      "attachments": "attachement",
      "id": 624,
      "transaction": {
        "id": 5991760,
        "domain": "test",
        "status": "success",
        "reference": "asjck8gf76zd1dr",
        "amount": 39100,
        "message": null,
        "gateway_response": "Successful",
        "paid_at": "2017-11-09T00:01:56.000Z",
        "created_at": "2017-11-09T00:01:36.000Z",
        "channel": "card",
        "currency": "NGN",
        "ip_address": null,
        "metadata": "",
        "log": null,
        "fees": 587,
        "fees_split": null,
        "authorization": {},
        "customer": {
          "international_format_phone": null
        },
        "plan": {},
        "subaccount": {},
        "split": {},
        "order_id": null,
        "paidAt": "2017-11-09T00:01:56.000Z",
        "createdAt": "2017-11-09T00:01:36.000Z",
        "requested_amount": null
      },
      "category": null,
      "customer": {
        "id": 10207,
        "first_name": null,
        "last_name": null,
        "email": "shola@baddest.com",
        "customer_code": "CUS_unz4q52yjsd6064",
        "phone": null,
        "metadata": null,
        "risk_action": "default",
        "international_format_phone": null
      },
      "organization": 1,
      "evidence": null,
      "resolvedAt": "2019-08-28T14:14:41.000Z",
      "createdAt": "2019-08-28T14:14:41.000Z",
      "updatedAt": "2019-08-28T14:29:07.000Z",
      "dueAt": null
    }
  ]
}`

const stat_400 = `{
  "status": false,
  "message": "Refund amount cannot be greater than initial amount"
}`

export { stat_200, stat_400 }
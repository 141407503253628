const stat_200 = `{
  "status": true,
  "message": "Refund retrieved",
  "data": {
    "id": 2733802,
    "integration": 463433,
    "domain": "test",
    "currency": "NGN",
    "transaction": 1183399071,
    "amount": 106000,
    "status": "processed",
    "dispute": null,
    "refunded_at": null,
    "refunded_by": "tolu@me.com",
    "customer_note": "Refund for transaction 413322_21938653397065",
    "merchant_note": "Refund for transaction 413322_21938653397065 by tolu@me.com",
    "createdAt": "2021-10-22T10:50:48.000Z",
    "deducted_amount": 106000,
    "fully_deducted": 1,
    "settlement": null,
    "customer": {
      "id": 47833988,
      "first_name": null,
      "last_name": null,
      "email": "demo@customer.com",
      "customer_code": "CUS_juupuydprvqmse6",
      "phone": null,
      "metadata": null,
      "risk_action": "default",
      "international_format_phone": null
    },
    "reason": "PROCESSED"
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import Callout from "../../components/Callout/Callout"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"


const FinalizeDisableOTP = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          <p>Finalize the request to disable OTP on your transfers.</p>
          <Callout type="info" title="Feature Availability">
            This feature is only available to businesses in Nigeria and Ghana.
          </Callout>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
        <section>
          <ParamsTable title="Body Param" hasOptional="false" data={props.data.body} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/transfers-control/finalize-disable-otp/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200: "200 OK" }} filepath="/transfers-control/finalize-disable-otp/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default FinalizeDisableOTP
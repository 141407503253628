import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import List from "./Disputes/List"
import Fetch from "./Disputes/Fetch"
import Transaction from "./Disputes/Transaction"
import Update from "./Disputes/Update"
import Evidence from "./Disputes/Evidence"
import UploadURL from "./Disputes/UploadURL"
import Resolve from "./Disputes/Resolve"
import Export from "./Disputes/Export"

import apis from "../data/dispute.json"

const Disputes = () => {

  return (
    <SectionLayout id="section-dispute">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Disputes" id="dispute" />
          <p>
            The Disputes API allows you manage transaction disputes on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Transaction data={ apis.transaction } />
        <Update data={ apis.update } />
        <Evidence data={ apis.evidence } />
        <UploadURL data={ apis["upload-url"] } />
        <Resolve data={ apis.resolve } />
        <Export data={ apis.export } />
      </>
    </SectionLayout>
  )
}

export default Disputes
const stat_200_ok = `{
  "status": true,
  "message": "Customer retrieved",
  "data": {
    "integration": 100032,
    "first_name": "Bojack",
    "last_name": "Horseman",
    "email": "bojack@horsinaround.com",
    "phone": null,
    "dedicated_account": {
      "bank": {
          "name": "Wema Bank",
          "id": 20,
          "slug": "wema-bank"
      },
      "id": 92747,
      "account_name": "PAYSTACKPAYME/ADEWUYI OLUGBENGA",
      "account_number": "7358520019",
      "created_at": "2021-02-09T09:13:49.000Z",
      "updated_at": "2021-02-09T09:13:49.000Z",
      "currency": "NGN",
      "split_config": {
          "id": 221,
          "name": "My Split",
          "type": "flat",
          "currency": "NGN",
          "integration": 100043,
          "domain": "live",
          "split_code": "SPL_goizzoc1rgizm53",
          "active": true,
          "bearer_type": "all",
          "bearer_subaccount": null,
          "createdAt": "2020-08-13T14:52:49.000Z",
          "updatedAt": "2020-08-13T14:52:49.000Z",
          "is_dynamic": true,
          "subaccounts": [
              {
                  "subaccount": {
                      "id": 246,
                      "subaccount_code": "ACCT_lf61e3lk2dqqlg1",
                      "business_name": "Bami FB",
                      "description": "Bami FB",
                      "primary_contact_name": null,
                      "primary_contact_email": null,
                      "primary_contact_phone": null,
                      "metadata": null,
                      "settlement_bank": "First Bank of Nigeria",
                      "currency": "NGN",
                      "account_number": "3055740701"
                  },
                  "share": 20000
              }
          ],
          "total_subaccounts": 1
      },
      "active": true,
      "assigned": true,
      "assignment": {
          "assignee_id": 973,
          "assignee_type": "Customer",
          "account_type": "PAY-WITH-TRANSFER-RECURRING",
          "integration": 100043
      }
  },
  "identified": true,
  "identifications": [
      {
          "country": "NG",
          "type": "bvn",
          "value": "223*****992"
      }
  ],
    "metadata": {
      "photos": [
        {
          "type": "twitter",
          "typeId": "twitter",
          "typeName": "Twitter",
          "url": "https://d2ojpxxtu63wzl.cloudfront.net/static/61b1a0a1d4dda2c9fe9e165fed07f812_a722ae7148870cc2e33465d1807dfdc6efca33ad2c4e1f8943a79eead3c21311",
          "isPrimary": true
        }
      ]
    "domain": "test",
    "customer_code": "CUS_xnxdt6s1zg1f4nx",
    "id": 1173,
    "transactions": [],
    "subscriptions": [],
    "authorizations": [],
    "createdAt": "2016-03-29T20:03:09.000Z",
    "updatedAt": "2016-03-29T20:03:10.000Z"
  }
}`

const stat_401_unauthorized = `{  
  "status":false,
  "message":"Invalid key"
}`

const stat_404_not_found = `{
  "status": false,
  "message": "Customer email is invalid for your live domain."
}`

export { stat_200_ok, stat_401_unauthorized, stat_404_not_found }
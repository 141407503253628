import React, { useEffect } from "react";

import './api-main.scss'

import Introduction from "../../api-reference/Introduction"
import Authentication from "../../api-reference/Authentication"
import Error from "../../api-reference/Error"
import Transaction from "../../api-reference/Transaction"
import Customer from "../../api-reference/Customer"
import DedicatedNuban from "../../api-reference/DedicatedNuban"
import ApplePay from "../../api-reference/ApplePay"
import Subaccount from "../../api-reference/Subaccount"
import Plan from "../../api-reference/Plan"
import Subscription from "../../api-reference/Subscription"
import Product from "../../api-reference/Product"
import PaymentPage from "../../api-reference/PaymentPage"
import Invoices from "../../api-reference/Invoices"
import Settlements from "../../api-reference/Settlements"
import TransferRecipient from "../../api-reference/TransferRecipient"
import Transfers from "../../api-reference/Transfers"
import TransfersControl from "../../api-reference/TransfersControl"
import BulkCharges from "../../api-reference/BulkCharges"
import ControlPanel from "../../api-reference/ControlPanel"
import Charge from "../../api-reference/Charge"
import Disputes from "../../api-reference/Disputes"
import Refunds from "../../api-reference/Refunds"
import Verification from "../../api-reference/Verification"
import Miscellaneous from "../../api-reference/Miscellaneous"
import TransactionSplit from "../../api-reference/TransactionSplit"

const APIMain = () => {

  useEffect(() => {
    let options = {
      rootMargin: '-100px',
      // rootMargin: '-150px',
      threshold: [...Array(100).keys()].map(x => x / 100)
      // threshold: [0.0, 0.25, 0.5, 0.75, 1.0]
    }
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        let id = entry.target.getAttribute('id');
        id = id.substring(id.indexOf('-') + 1);
        // if (entry.intersectionRatio > 0 && entry.boundingClientRect.y > 0 && entry.boundingClientRect.y <= 935) {
        // if (entry.intersectionRatio > 0 ) {
        // if (entry.boundingClientRect.top >= 0 &&  entry.boundingClientRect.top <= 200) {
        if (entry.boundingClientRect.top <= 280 && entry.boundingClientRect.bottom >= 150) {
          // if (entry.isIntersecting ) {
          // if (entry.isIntersecting && entry.intersectionRect.top >= 100 && entry.intersectionRect.top <= 250 ) {
          let currentItem = document.querySelector(`ul li a[href="#${id}"]`)

          currentItem.classList.add('active');

        } else {
          document.querySelector(`ul li a[href="#${id}"]`).classList.remove('active');
        }
      });
    }, options);

    document.querySelectorAll('section[id]').forEach((section) => {
      observer.observe(section);
    });
  }, [])

  return (
    <>
      <Introduction />
      <Authentication />
      <Error />
      <Transaction />
      <TransactionSplit />
      <Customer />
      <DedicatedNuban />
      <ApplePay />
      <Subaccount />
      <Plan />
      <Subscription />
      <Product />
      <PaymentPage />
      <Invoices />
      <Settlements />
      <TransferRecipient />
      <Transfers />
      <TransfersControl />
      <BulkCharges />
      <ControlPanel />
      <Charge />
      <Disputes />
      <Refunds />
      <Verification />
      <Miscellaneous />

    </>
  )
}

export default APIMain
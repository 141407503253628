const stat_200_ok = `{
  "status": true,
  "message": "Authorization is valid for this amount",
  "data": {
    "amount": "400",
    "currency": "NGN"
  }
}`

const stat_400_invalid = `{
  "status": false,
  "message": "Insufficient Funds"
}`

export { stat_200_ok, stat_400_invalid }
const stat_200 = `{
  "status": true,
  "message": "Customer created",
  "data": {
    "email": "customer@email.com",
    "integration": 100032,
    "domain": "test",
    "customer_code": "CUS_xnxdt6s1zg1f4nx",
    "id": 1173,
    "identified": false,
    "identifications":null,
    "createdAt": "2016-03-29T20:03:09.584Z",
    "updatedAt": "2016-03-29T20:03:09.584Z"
  }
}`

export { stat_200 }
const stat_200 = `{
  "status": true,
  "message": "Customers retrieved",
  "data": [
    {
      "integration": 100032,
      "first_name": "Bojack",
      "last_name": "Horseman",
      "email": "bojack@horsinaround.com",
      "phone": null,
      "metadata": {
        "photos": [
          {
            "type": "twitter",
            "typeId": "twitter",
            "typeName": "Twitter",
            "url": "https://d2ojpxxtu63wzl.cloudfront.net/static/61b1a0a1d4dda2c9fe9e165fed07f812_a722ae7148870cc2e33465d1807dfdc6efca33ad2c4e1f8943a79eead3c21311",
            "isPrimary": true
          }
        ]
      },
      "domain": "test",
      "customer_code": "CUS_xnxdt6s1zg1f4nx",
      "id": 1173,
      "createdAt": "2016-03-29T20:03:09.000Z",
      "updatedAt": "2016-03-29T20:03:10.000Z"
    },
    {
      "integration": 100032,
      "first_name": "Diane",
      "last_name": "Nguyen",
      "email": "diane@writersclub.com",
      "phone": "16504173147",
      "metadata": null,
      "domain": "test",
      "customer_code": "CUS_1uld4hluw0g2gn0",
      "id": 63,
      "createdAt": "2016-01-13T01:15:47.000Z",
      "updatedAt": "2016-02-24T16:56:48.000Z"
    },
    {
      "integration": 100032,
      "first_name": null,
      "last_name": null,
      "email": "todd@chavez.com",
      "phone": null,
      "metadata": null,
      "domain": "test",
      "customer_code": "CUS_soirsjdqkyjfwcr",
      "id": 65,
      "createdAt": "2016-01-13T01:15:47.000Z",
      "updatedAt": "2016-01-13T01:15:47.000Z"
    }
  ],
  "meta": {
    "total": 3,
    "skipped": 0,
    "perPage": 50,
    "page": 1,
    "pageCount": 1
  }
}`

export { stat_200 }
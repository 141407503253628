import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Subscriptions/Create"
import List from "./Subscriptions/List"
import Fetch from "./Subscriptions/Fetch"
import Enable from "./Subscriptions/Enable"
import Disable from "./Subscriptions/Disable"
import GenerateUpdateSubscriptionLink from "./Subscriptions/GenerateUpdateSubscriptionLink"
import SendUpdateSubscriptionLink from "./Subscriptions/SendUpdateSubscriptionLink"

import Callout from "../components/Callout/Callout"

import apis from "../data/subscription.json"

const Subscription = () => {

  return (
    <SectionLayout id="section-subscription">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Subscriptions" id="subscription" />
          <p>
            The Subscriptions API allows you create and manage recurring payment on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Enable data={ apis.enable } />
        <Disable data={ apis.disable } />
        <GenerateUpdateSubscriptionLink data={ apis["manage-link"] } />
        <SendUpdateSubscriptionLink data={ apis["manage-email"] } /> 
      </>
    </SectionLayout>
  )
}

export default Subscription
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"

import Register from "./ApplePay/Register"
import Unregister from "./ApplePay/Unregister"
import List from "./ApplePay/List"

import apis from "../data/apple-pay.json"

const ApplePay = () => {

  return (
    <SectionLayout id="section-apple-pay">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Apple Pay" id="apple-pay" />
          <p>
            The Apple Pay API allows you register your application's top-level domain or subdomain 
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Register data={ apis["register-domain"] } />
        <List data={ apis["list-domains"] } />
        <Unregister data={ apis["unregister-domain"] } />
      </>
    </SectionLayout>
  )
}

export default ApplePay
const stat_200 = `{
  "status": true,
  "message": "States retrieved",
  "data": [
    {
      "name": "Alberta",
      "slug": "alberta",
      "abbreviation": "AB"
    },
    {
      "name": "British Columbia",
      "slug": "british-columbia",
      "abbreviation": "BC"
    }
  ]
}`

export { stat_200 }
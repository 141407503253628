const stat_200 = `{
  "status": true,
  "message": "Dispute retrieved successfully",
  "data": {
    "history": [
      {
        "id": 6094,
        "dispute": 2867,
        "status": "pending",
        "by": "demo@test.co",
        "createdAt": "2017-11-16T16:12:24.000Z",
        "updatedAt": "2017-11-16T16:12:24.000Z"
      }
    ],
    "messages": [
      {
        "sender": "demo@test.co",
        "body": "test this",
        "dispute": 2867,
        "id": 148,
        "is_deleted": 0,
        "createdAt": "2017-11-16T16:12:24.000Z",
        "updatedAt": "2017-11-16T16:12:24.000Z"
      }
    ],
    "currency": null,
    "last4": null,
    "bin": null,
    "transaction_reference": null,
    "merchant_transaction_reference": null,
    "refund_amount": null,
    "status": "archived",
    "domain": "test",
    "resolution": null,
    "category": null,
    "note": null,
    "attachments": "[]",
    "id": 2867,
    "integration": 100043,
    "transaction": {
      "id": 5991760,
      "domain": "test",
      "status": "success",
      "reference": "asjck8gf76zd1dr",
      "amount": 39100,
      "message": null,
      "gateway_response": "Successful",
      "paid_at": "2017-11-09T00:01:56.000Z",
      "created_at": "2017-11-09T00:01:36.000Z",
      "channel": "card",
      "currency": "NGN",
      "ip_address": null,
      "metadata": "",
      "log": null,
      "fees": 587,
      "fees_split": null,
      "authorization": {},
      "customer": {
        "international_format_phone": null
      },
      "plan": {},
      "subaccount": {},
      "split": {},
      "order_id": null,
      "paidAt": "2017-11-09T00:01:56.000Z",
      "createdAt": "2017-11-09T00:01:36.000Z",
      "requested_amount": null
    },
    "created_by": null,
    "evidence": null,
    "resolvedAt": null,
    "createdAt": "2017-11-16T16:12:24.000Z",
    "updatedAt": "2019-08-16T08:05:25.000Z",
    "dueAt": null
  }
}` 

export { stat_200 }
import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"

import "./api-nav.scss";

import Home from '../../assets/images/svg/home.svg';
import Payments from '../../assets/images/svg/payments.svg';
import Transfers from '../../assets/images/svg/transfers.svg';
import Cancel from '../../assets/images/svg/cancel.svg';

const APINav = (props) => {
  const apis = useStaticQuery(
    graphql`
      query {
        allApisJson {
          edges {
            node {
              title
              route
              method
              items {
                method
                route
                title
              }
            }
          }
        }
      }    
    `
  );

  const getRoute = (route) => {
    return route.replace('/', '#').replace('/', '-')
  }

  const getNavItem = () => {
    let navItems = [];
    apis.allApisJson.edges.map((item, index) => { 
      navItems.push(
        <li key={index}>
          <a href={ getRoute(item.node.route) }>
            { item.node.title }
          </a>
          { item.node.items.length > 0 && (
            <ul className="api-sub-menu">
              {item.node.items .map((sublink, index) => (
                <li key={index}>
                  <a href={ getRoute(sublink.route) }>
                    { sublink.title }
                  </a>
                </li>
              ))}
            </ul>
          )}
        </li>
      )
    })

    return navItems;
  }

  return (
    <div className="nav">
      <div className="nav__action">
        <button className="action__button" onClick={props.toggleNav}>
          <Cancel className="action__button__icon" />
          <span className="action__button__text action__button__text--right">
            Close
          </span>
        </button>
      </div>
      <div className="nav-static">
        <ul>
          <li className="api-static-item">
            <a href="#introduction">
              <span><Home /></span>
              <span>Introduction</span>
            </a>
          </li>
          <li className="api-static-item">
            <a href="#authentication">
              <span><Payments /></span>
              <span>Authentication</span>
            </a>
          </li>
          <li className="api-static-item">
            <a href="#errors">
              <span><Transfers /></span>
              <span>Errors</span>
            </a>
          </li>
        </ul>
      </div>
      <div className="nav-dynamic">
        <div className="nav-dynamic--label">
          <span>API Endpoints</span>
          <hr />
        </div>
        <ul className="api-main-menu">
          {getNavItem()}
        </ul>
      </div>
    </div>
  )
}


export default APINav
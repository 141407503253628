const bash = `curl https://api.paystack.co/refund
  -H 'authorization: Bearer YOUR_SECRET_KEY'
  -H 'cache-control: no-cache'
  -H 'content-type: application/json'
  -d '{ "transaction":"qufywna9w9a5d8v", "amount":"10000" }
  -X POST
`

const js = `const https = require('https')

const params = JSON.stringify({
  "transaction": "qufywna9w9a5d8v",
  "amount": "10000"
})

const options = {
  hostname: 'api.paystack.co',
  port: 443,
  path: '/refund',
  method: 'POST',
  headers: {
    Authorization: 'Bearer SECRET_KEY',
    'Content-Type': 'application/json'
  }
}

const req = https.request(options, res => {
  let data = ''

  res.on('data', (chunk) => {
    data += chunk
  });

  res.on('end', () => {
    console.log(JSON.parse(data))
  })
}).on('error', error => {
  console.error(error)
})

req.write(params)
req.end()`

const php = `<?php
  $url = "https://api.paystack.co/refund";

  $fields = [
    'transaction' => "qufywna9w9a5d8v",
    'amount' => "10000",
  ];

  $fields_string = http_build_query($fields);

  //open connection
  $ch = curl_init();
  
  //set the url, number of POST vars, POST data
  curl_setopt($ch,CURLOPT_URL, $url);
  curl_setopt($ch,CURLOPT_POST, true);
  curl_setopt($ch,CURLOPT_POSTFIELDS, $fields_string);
  curl_setopt($ch, CURLOPT_HTTPHEADER, array(
    "Authorization: Bearer SECRET_KEY",
    "Cache-Control: no-cache",
  ));
  
  //So that curl_exec returns the contents of the cURL; rather than echoing it
  curl_setopt($ch,CURLOPT_RETURNREFUND, true); 
  
  //execute post
  $result = curl_exec($ch);
  echo $result;
?>`

export { bash, js, php }
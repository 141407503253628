const stat_200 = `{
  "status": true,
  "message": "Subscription successfully created",
  "data": {
    "customer": 1173,
    "plan": 28,
    "integration": 100032,
    "domain": "test",
    "start": 1459296064,
    "status": "active",
    "quantity": 1,
    "amount": 50000,
    "authorization": {
      "authorization_code": "AUTH_6tmt288t0o",
      "bin": "408408",
      "last4": "4081",
      "exp_month": "12",
      "exp_year": "2020",
      "channel": "card",
      "card_type": "visa visa",
      "bank": "TEST BANK",
      "country_code": "NG",
      "brand": "visa",
      "reusable": true,
      "signature": "SIG_uSYN4fv1adlAuoij8QXh",
      "account_name": "BoJack Horseman"
    },
    "subscription_code": "SUB_vsyqdmlzble3uii",
    "email_token": "d7gofp6yppn3qz7",
    "id": 9,
    "createdAt": "2016-03-30T00:01:04.687Z",
    "updatedAt": "2016-03-30T00:01:04.687Z"
  }
}`

const stat_404 = `{
  "status": false,
  "message": "The customer specified has no saved authorizations"
}`

export { stat_200, stat_404 }
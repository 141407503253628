import React, { useState } from "react"

import "./params-table.scss"

const ParamsTable = (props) => {

  const [showOptional, setShowOptional] = useState(false)

  const toggleOptional = () => {
    setShowOptional(!showOptional)
  }

  const parseData = (data) => {
    let group = data.reduce((result, item) => {
      result[item.required] = [...result[item.required] || [], item];
      return result;
     }, {});

     return group;
  }

  return (
    <>
      { props.hasOptional === "true" ?
      (
        <table className="params-holder">
          <caption className="params-group">{ props.title }</caption>
          <tbody>
            { parseData(props.data)["true"].map((item, index) => (
              <tr key={index}>
                <td className="param-details">
                  <div className="param param-name">{ item.param }</div>
                  <div className="param param-type">{ item.type }</div>
                </td>
                <td dangerouslySetInnerHTML={{ __html: item.description }}></td>
              </tr>
            )) }
            { parseData(props.data)["false"].map((item, index) => (
              <tr className={`param-optional ${showOptional ? 'active' : ''}`} key={index}>
                <td className="param-details">
                  <div className="param param-name">{ item.param }</div>
                  <div className="param param-type">{ item.type }</div>
                </td>
                <td><div className="param param-desc" dangerouslySetInnerHTML={{ __html: item.description }}></div></td>
              </tr>
            )) }
          </tbody>
          { props.hasOptional === 'true' ? (
            <tbody>
            <tr className="optional-toggle" onClick={() => toggleOptional()}>
              <th className="optional-toggle__title" colSpan="3">
                <span className={`optional-toggle-icon ${showOptional ? 'active' : ''}`}></span>
                <span className="optional-toggle-text">{showOptional ? 'Hide': 'Show'} optional parameters</span>
              </th>
            </tr>
          </tbody>
          ) : (' ')}
        </table>
      ) :
      (
        <table className="params-holder">
          <caption className="params-group">{ props.title }</caption>
          <tbody>
            { parseData(props.data)["true"].map((item, index) => (
              <tr key={ index }>
                <td className="param-details">
                  <div className="param param-name">{ item.param }</div>
                  <div className="param param-type">{ item.type }</div>
                </td>
                <td dangerouslySetInnerHTML={{ __html: item.description }}></td>
              </tr>
            )) }
          </tbody>
        </table>
      )}
    </>
  )
}

export default ParamsTable
const stat_200 = `{
  "status": true,
  "message": "Split group updated",
  "data": {
    "id": 95,
    "name": "Updated Name",
    "type": "percentage",
    "currency": "NGN",
    "integration": 165956,
    "domain": "live",
    "split_code": "SPL_uMzcGbG5ca",
    "active": false,
    "bearer_type": "all",
    "bearer_subaccount": null,
    "createdAt": "2020-06-22T16:20:53.000Z",
    "updatedAt": "2020-06-22T17:26:59.000Z",
    "subaccounts": [
      {
        "subaccount": {
          "id": 12700,
          "subaccount_code": "ACCT_jsuq5uwf3n8la7b",
          "business_name": "Ayobami GTB",
          "description": "Ayobami GTB",
          "primary_contact_name": null,
          "primary_contact_email": null,
          "primary_contact_phone": null,
          "metadata": null,
          "percentage_charge": 20,
          "settlement_bank": "Guaranty Trust Bank",
          "account_number": "0259198351"
        },
        "share": 80
      }
    ],
    "total_subaccounts": 1
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Products/Create"
import List from "./Products/List"
import Fetch from "./Products/Fetch"
import Update from "./Products/Update"

import apis from "../data/product.json"

const Product = () => {

  return (
    <SectionLayout id="section-product">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Products" id="product" />
          <p>
            The Products API allows you create and manage inventories on your integration
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
      </>
    </SectionLayout>
  )
}

export default Product
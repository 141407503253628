const stat_200 = `{
  "status": true,
  "message": "Recipients added successfully",
  "data": {
    "success": [
      {
        "domain": "test",
        "name": "Habenero Mundane",
        "type": "nuban",
        "description": "",
        "integration": 463433,
        "currency": "NGN",
        "metadata": null,
        "details": {
          "account_number": "0123456789",
          "account_name": null,
          "bank_code": "033",
          "bank_name": "United Bank For Africa"
        },
        "recipient_code": "RCP_wh5k8r4vzuh5c94",
        "active": true,
        "id": 10152540,
        "isDeleted": false,
        "createdAt": "2020-11-09T10:12:48.213Z",
        "updatedAt": "2020-11-09T10:12:48.213Z"
      },
      {
        "domain": "test",
        "name": "Soft Merry",
        "type": "nuban",
        "description": "",
        "integration": 463433,
        "currency": "NGN",
        "metadata": null,
        "details": {
          "account_number": "98765432310",
          "account_name": null,
          "bank_code": "50211",
          "bank_name": "Kuda Bank"
        },
        "recipient_code": "RCP_yu1kkyktoljnczg",
        "active": true,
        "id": 10152541,
        "isDeleted": false,
        "createdAt": "2020-11-09T10:12:48.213Z",
        "updatedAt": "2020-11-09T10:12:48.213Z"
      }
    ],
      "errors": []
  }
}`

export { stat_200 }
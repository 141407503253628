import React from 'react';

import SectionLayout from '../components/SectionLayout/SectionLayout';
import Permalink from '../components/Permalink/Permalink';
import Callout from '../components/Callout/Callout'

import MatchBVN from './Verification/MatchBVN';
// import ResolveBVN from './Verification/ResolveBVN';
import ResolveAccount from './Verification/ResolveAccount';
import ResolveCard from './Verification/ResolveCard';
// import ResolveBVNPremium from './Verification/ResolveBVNPremium';

import apis from '../data/verification.json';

const Verification = () => {
	return (
		<SectionLayout id="section-verification">
			<>
				<header className="inner_header">
					<Permalink type="h1" title="Verification" id="verification" />
					<p>The Verification API allows you perform KYC processes</p>
					<Callout type="info" title="Feature Availability">
						This feature is only available to businesses in Nigeria.
					</Callout>
				</header>
			</>
			<></>
			<>
				<MatchBVN data={apis['match-bvn']} />
				{/* <ResolveBVN data={apis['resolve-bvn']} /> */}
				{/* <ResolveBVNPremium data={apis['resolve-bvn-premium']} /> */}
				<ResolveAccount data={apis['resolve-account']} />
				<ResolveCard data={apis['resolve-card']} />
			</>
		</SectionLayout>
	);
};

export default Verification;

import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Refunds/Create"
import List from "./Refunds/List"
import Fetch from "./Refunds/Fetch"

import apis from "../data/refund.json"

const Refund = () => {

  return (
    <SectionLayout id="section-refund">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Refunds" id="refund" />
          <p>
            The Refunds API allows you create and manage transaction refunds 
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
      </>
    </SectionLayout>
  )
}

export default Refund
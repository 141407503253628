const stat_200 = `{
  "status": true,
  "message": "Split created",
  "data": {
    "id": 142,
    "name": "Test Doc",
    "type": "percentage",
    "currency": "NGN",
    "integration": 428626,
    "domain": "test",
    "split_code": "SPL_e7jnRLtzla",
    "active": true,
    "bearer_type": "subaccount",
    "bearer_subaccount": 40809,
    "createdAt": "2020-06-30T11:42:29.150Z",
    "updatedAt": "2020-06-30T11:42:29.150Z",
    "subaccounts": [
      {
        "subaccount": {
          "id": 40809,
          "subaccount_code": "ACCT_z3x6z3nbo14xsil",
          "business_name": "Business Name",
          "description": "Business Description",
          "primary_contact_name": null,
          "primary_contact_email": null,
          "primary_contact_phone": null,
          "metadata": null,
          "percentage_charge": 20,
          "settlement_bank": "Business Bank",
          "account_number": "1234567890"
        },
        "share": 20
      },
      {
        "subaccount": {
          "id": 40809,
          "subaccount_code": "ACCT_pwwualwty4nhq9d",
          "business_name": "Business Name",
          "description": "Business Description",
          "primary_contact_name": null,
          "primary_contact_email": null,
          "primary_contact_phone": null,
          "metadata": null,
          "percentage_charge": 20,
          "settlement_bank": "Business Bank",
          "account_number": "0123456789"
        },
        "share": 30
      }
    ],
    "total_subaccounts": 2
  }
}`

export { stat_200 }
const stat_200 = `{
  "status": true,
  "message": "Settlements retrieved",
  "data": [
    {
      "integration": 199999,
      "settled_by": null,
      "settlement_date": "2016-11-04T00:00:00.000Z",
      "domain": "live",
      "total_amount": 28350,
      "status": "pending",
      "id": 8597,
      "createdAt": "2016-11-04T00:00:00.000Z",
      "updatedAt": null
    },
    {
      "integration": 199999,
      "subaccount": {
        "domain": "live",
        "subaccount_code": "ACCT_83uhio98ueej",
        "business_name": "Someone, Somewhere",
        "description": null,
        "primary_contact_name": null,
        "primary_contact_email": null,
        "primary_contact_phone": null,
        "metadata": null,
        "percentage_charge": 1,
        "is_verified": false,
        "settlement_bank": "Guaranty Trust Bank",
        "account_number": "0909090909",
        "settlement_schedule": "WEEKLY",
        "active": true,
        "migrate": null,
        "id": 23,
        "integration": 199999,
        "createdAt": "2016-10-03T13:14:36.000Z",
        "updatedAt": "2016-11-03T14:21:52.000Z"
      },
      "settled_by": null,
      "settlement_date": "2016-11-04T00:00:00.000Z",
      "domain": "live",
      "total_amount": 80000,
      "status": "pending",
      "id": 8598,
      "createdAt": "2016-11-04T00:00:00.000Z",
      "updatedAt": null
    },
    {
      "integration": 199999,
      "settled_by": null,
      "settlement_date": "2016-09-12T00:00:00.000Z",
      "domain": null,
      "total_amount": 9850,
      "status": "success",
      "id": 5625,
      "createdAt": "2016-09-12T00:00:00.000Z",
      "updatedAt": null
    }
  ],
  "meta": {
    "total": 4,
    "skipped": 0,
    "perPage": 50,
    "page": 1,
    "pageCount": 1
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./TransactionSplits/Create"
import List from "./TransactionSplits/List"
import Fetch from "./TransactionSplits/Fetch"
import Update from "./TransactionSplits/Update"
import AddSubaccount from "./TransactionSplits/AddSubaccount"
import RemoveSubaccount from "./TransactionSplits/RemoveSubaccount"

import apis from "../data/transaction-splits.json"

const TransactionSplit = () => {

  return (
    <SectionLayout id="section-split">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Transaction Splits" id="split" />
          <p>
            The Transaction Splits API enables merchants split the settlement for a transaction across their payout account, and one or more Subaccounts.
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
        <AddSubaccount data={ apis["add-subaccount"] } />
        <RemoveSubaccount data={ apis["remove-subaccount"] } />
      </>
    </SectionLayout>
  )
}

export default TransactionSplit
import React from 'react';

import SectionLayout from '../../components/SectionLayout/SectionLayout';
import Permalink from '../../components/Permalink/Permalink';
import ParamsTable from '../../components/ParamsTable/ParamsTable';
import APIReqRes from '../../components/APIReqRes/APIReqRes';

const Create = props => {
	return (
		<SectionLayout id={`section-${props.data.id}`} code="true">
			<>
				<Permalink type="h2" title={props.data.title} id={props.data.id} />
				<section>Initiate a refund for a transaction</section>
				<section>
					<ParamsTable
						title="Headers"
						hasOptional="false"
						data={props.data.header}
					/>
				</section>
				<section>
					<ParamsTable
						title="Body Param"
						hasOptional="true"
						data={props.data.body}
					/>
				</section>
			</>
			<>
				<section>
					<APIReqRes
						type="request"
						links={{ bash: 'cURL', js: 'Node', php: 'PHP' }}
						filepath="/refunds/create/requests.js"
						title={{ method: props.data.method, url: props.data.route }}
					/>
					<APIReqRes
						type="response"
						links={{ stat_200: '200 OK', stat_400: '400 Bad Request' }}
						filepath="/refunds/create/response.js"
					/>
				</section>
			</>
		</SectionLayout>
	);
};

export default Create;

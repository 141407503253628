import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./PaymentPages/Create"
import List from "./PaymentPages/List"
import Fetch from "./PaymentPages/Fetch"
import Update from "./PaymentPages/Update"
import CheckSlug from "./PaymentPages/CheckSlug"
import AddProduct from "./PaymentPages/AddProduct"

import apis from "../data/payment-page.json"

const PaymentPage = () => {

  return (
    <SectionLayout id="section-page">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Payment Pages" id="page" />
          <p>
            The Payment Pages API provides a quick and secure way to collect payment for products.
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
        <CheckSlug data={ apis["check-slug"] } />
        <AddProduct data={ apis["add-products"] } />
      </>
    </SectionLayout>
  )
}

export default PaymentPage
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Callout from "../components/Callout/Callout"

import Create from "./TransferRecipients/Create"
import Bulk from "./TransferRecipients/Bulk"
import List from "./TransferRecipients/List"
import Fetch from "./TransferRecipients/Fetch"
import Update from "./TransferRecipients/Update"
import Delete from "./TransferRecipients/Delete"

import apis from "../data/transfer-recipient.json"

const TransferRecipient = () => {

  return (
    <SectionLayout id="section-transfer-recipient">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Transfer Recipients" id="transfer-recipient" />
          <p>
            The Transfer Recipients API allows you create and manage beneficiaries that you send money to
          </p>
        </header>
        <Callout type="info" title="Feature Availability">
          This feature is only available to businesses in Nigeria and Ghana.
        </Callout>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <Bulk data={ apis.bulk } />
        <List data={ apis.list } />
        <Fetch data={ apis.fetch } />
        <Update data={ apis.update } />
        <Delete data={ apis.delete } />
      </>
    </SectionLayout>
  )
}

export default TransferRecipient
import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import ExternalLink from "../../components/ExternalLink/ExternalLink"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"


const Create = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          <p>Initiate a payment by integrating the <ExternalLink link={ process.env.GATSBY_DOC_URL + '/payments/payment-channels' } title="payment channel" /> of your choice.</p>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
        <section>
          <ParamsTable title="Body Param" hasOptional="true" data={props.data.body} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/charges/create/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200: "200 OK", stat_200_otp: "200 OTP", stat_200_pending: "200 Pending", stat_200_pin: "200 PIN", stat_200_failed: "200 Failed", stat_200_bank_auth: "200 Bank Auth", stat_200_phone: "200 Phone", stat_200_birthday: "200 Birthday", stat_200_ussd: "200 USSD", stat_200_mobile_money: "200 Mobile Money", stat_200_address: "200 Address", stat_400: "400 Bad Request" }} filepath="/charges/create/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default Create
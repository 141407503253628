import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Create from "./Charge/Create"
import SubmitPin from "./Charge/SubmitPin"
import SubmitOTP from "./Charge/SubmitOTP"
import SubmitPhone from "./Charge/SubmitPhone"
import SubmitBirthday from "./Charge/SubmitBirthday"
import SubmitAddress from "./Charge/SubmitAddress"
import Check from "./Charge/Check"

import apis from "../data/charge.json"

const Charge = () => {

  return (
    <SectionLayout id="section-charge">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Charge" id="charge" />
          <p>
            The Charge API allows you to configure payment channel of your choice when initiating a payment.
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Create data={ apis.create } />
        <SubmitPin data={ apis["submit-pin"] } />
        <SubmitOTP data={ apis["submit-otp"] } />
        <SubmitPhone data={ apis["submit-phone"] } />
        <SubmitBirthday data={ apis["submit-birthday"] } />
        <SubmitAddress data={ apis["submit-address"] } />
        <Check data={ apis.check } />
      </>
    </SectionLayout>
  )
}

export default Charge
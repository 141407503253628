const stat_200 = `{
  "status": true,
  "message": "Refund has been queued for processing",
  "data": {
    "transaction": 1259865949,
    "integration": 412829,
    "deducted_amount": "10000",
    "channel": null,
    "merchant_note": "Refund for transaction tvunjbbdaz by test@me.com",
    "customer_note": "Refund for transaction tvunjbbdaz",
    "status": "processing",
    "refunded_by": "test@me.com",
    "expected_at": "2021-12-03T11:47:10.000Z",
    "currency": "NGN",
    "domain": "live",
    "amount": 10000,
    "fully_deducted": true,
    "id": 3018259,
    "createdAt": "2021-11-24T11:47:10.000Z",
    "updatedAt": "2021-11-24T11:47:17.000Z"
  }
}`;

const stat_400 = `{
	"status": false,
	"message": "Cannot refund less than NGN50",
	"data": {}
}`;

export { stat_200, stat_400 };

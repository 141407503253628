const stat_200_ok = `{
  "status": true,
  "message": "Charge attempted",
  "data": {
    "amount": 27000,
    "currency": "NGN",
    "transaction_date": "2020-05-27T11:45:03.000Z",
    "status": "success",
    "reference": "cn65lf4ixmkzvda",
    "domain": "test",
    "metadata": "",
    "gateway_response": "Approved",
    "message": null,
    "channel": "card",
    "ip_address": null,
    "log": null,
    "fees": 14500,
    "authorization": {
      "authorization_code": "AUTH_pmx3mgawyd",
      "bin": "408408",
      "last4": "4081",
      "exp_month": "12",
      "exp_year": "2020",
      "channel": "card",
      "card_type": "visa DEBIT",
      "bank": "Test Bank",
      "country_code": "NG",
      "brand": "visa",
      "reusable": true,
      "signature": "SIG_2Gvc6pNuzJmj4TCchXfp",
      "account_name": null
    },
    "customer": {
      "id": 23215815,
      "first_name": null,
      "last_name": null,
      "email": "mail@mail.com",
      "customer_code": "CUS_wt0zmhzb0xqd4nr",
      "phone": null,
      "metadata": null,
      "risk_action": "default"
    },
    "plan": null,
    "id": 696105928
  }
}`

const stat_200_failed = `{  
  "status":true,
  "message":"Charge Attempted",
  "data":{  
    "amount":27000,
    "currency":"NGN",
    "transaction_date":"2016-10-01T11:03:09.000Z",
    "status":"failed",
    "reference":"DG4uishudoq90LD",
    "domain":"test",
    "metadata":0,
    "gateway_response":"Insufficient Funds",
    "message":null,
    "channel":"card",
    "ip_address":"41.1.25.1",
    "log":null,
    "fees":null,
    "authorization": {
      "authorization_code": "AUTH_5z72ux0koz",
      "bin": "408408",
      "last4": "4081",
      "exp_month": "12",
      "exp_year": "2020",
      "channel": "card",
      "card_type": "visa DEBIT",
      "bank": "Test Bank",
      "country_code": "NG",
      "brand": "visa",
      "reusable": true,
      "signature": "SIG_ZdUx7Z5ujd75rt9OMTN4",
      "account_name": "BoJack Horseman"
    },
    "customer":{  
      "id":84312,
      "customer_code":"CUS_hdhye17yj8qd2tx",
      "first_name":"BoJack",
      "last_name":"Horseman",
      "email":"bojack@horseman.com"
    },
    "plan":"PLN_0as2m9n02cl0kp6"
  }
}`

const stat_400_invalid = `{  
  "status": false,
  "message": "Invalid key"
}`

export { stat_200_ok, stat_200_failed, stat_400_invalid }
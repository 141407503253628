import React from 'react'
import styled from "styled-components"
import Highlight, { defaultProps } from 'prism-react-renderer'
import DarkTheme from 'prism-react-renderer/themes/nightOwl'
import LightTheme from 'prism-react-renderer/themes/nightOwlLight'

const codeTheme = {
  dark: DarkTheme,
  light: LightTheme
}

const Container = styled.div`
  padding: 0px;
`

const Pre = styled.pre`
  position: relative;
  text-align: left;
  margin: 1em 0;
  padding: 24px 16px;
  margin: 0;
  overflow-x: auto;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: ${props => props.dark ? '#011B33' : '#FFF'};
  max-height: 300px;

  & .token-line {
    line-height: 1.5;
  }
`

const LineNo = styled.span`
  display: inline-block;
  width: 2em;
  user-select: none;
  opacity: 0.3;
`


const CodeSnippet = ({language, codeString, theme}) => {

  return (
    <Container>
      <Highlight {...defaultProps} theme={codeTheme[theme]} code={codeString} language={language}>
        {({ tokens, getLineProps, getTokenProps }) => (
          <Pre dark={theme === 'dark'}>
            {tokens.map((line, i) => (
              <div {...getLineProps({ line, key: i })}>
                <LineNo>{i + 1}</LineNo>
                {line.map((token, key) => <span {...getTokenProps({ token, key })} />)}
              </div>
            ))}
          </Pre>
        )}
      </Highlight>
    </Container>
  )
}


export default CodeSnippet
import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import Callout from "../../components/Callout/Callout"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"


const DisableOTP = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          <p>
            This is used in the event that you want to be able to complete transfers programmatically without use of OTPs. No arguments required. You will get an OTP to complete the request.
          </p>
          <Callout type="info" title="Feature Availability">
            This feature is only available to businesses in Nigeria and Ghana.
          </Callout>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/transfers-control/disable-otp/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200: "200 OK" }} filepath="/transfers-control/disable-otp/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default DisableOTP
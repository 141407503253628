import React from "react"

import SectionLayout from "../../components/SectionLayout/SectionLayout"
import Permalink from "../../components/Permalink/Permalink"
import ParamsTable from "../../components/ParamsTable/ParamsTable"
import APIReqRes from "../../components/APIReqRes/APIReqRes"


const Initiate = (props) => {

  return (
    <SectionLayout id={`section-${props.data.id}`} code="true">
      <>
        <Permalink type="h2" title={props.data.title} id={props.data.id} />
        <section>
          <p>Send an array of objects with authorization codes and amount (in <strong>kobo</strong> if currency is <code>NGN</code>, <strong>pesewas</strong>, if currency is <code>GHS</code>, and <strong>cents</strong>, if currency is <code>ZAR</code> ) so we can process transactions as a batch.</p>
        </section>
        <section>
          <ParamsTable title="Headers" hasOptional="false" data={props.data.header} />
        </section>
        <section>
          <ParamsTable title="Body Param" hasOptional="false" data={props.data.body} />
        </section>
      </>
      <>
        <section>
          <APIReqRes type="request" links={{ bash: "cURL", js: "Node", php: "PHP" }} filepath="/bulk-charges/initiate/requests.js" title={{ method: props.data.method, url: props.data.route }} />
          <APIReqRes type="response" links={{ stat_200: "200 OK" }} filepath="/bulk-charges/initiate/response.js" />
        </section>
      </>
    </SectionLayout>
  )
}

export default Initiate
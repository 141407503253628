const stat_200 = `{
  "status": true,
  "message": "Charges have been queued",
  "data": {
    "domain": "test",
    "batch_code": "BCH_180tl7oq7cayggh",
    "status": "active",
    "id": 17,
    "integration": 100073,
    "createdAt": "2017-02-04T05:44:19.000Z",
    "updatedAt": "2017-02-04T05:44:19.000Z"
  }
}`

export { stat_200 }
import React from "react"

import SectionLayout from "../components/SectionLayout/SectionLayout"
import Permalink from "../components/Permalink/Permalink"
import Fetch from "./Settlements/Fetch"
import FetchTransactions from "./Settlements/FetchTransactions"

import apis from "../data/settlement.json"

const Settlements = () => {

  return (
    <SectionLayout id="section-settlement">
      <>
        <header className="inner_header">
          <Permalink type="h1" title="Settlements" id="settlement" />
          <p>
            The Settlements API allows you gain insights into payouts made by Paystack to your bank account
          </p>
        </header>
      </>
      <>
        
      </>
      <>
        <Fetch data={ apis.fetch } />
        <FetchTransactions data={ apis["fetch-transaction"] } />
      </>
    </SectionLayout>
  )
}

export default Settlements
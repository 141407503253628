const stat_200 = `{
  "status": true,
  "message": "Apple Pay registered domains retrieved",
  "data": {
    "domainNames": [
      "example.com"
    ]
  }
}` 

export { stat_200 }